<template>
  <div class="consumer_freshdesk_widget">
    <div class="c_wrapper">
      <div>
        <script
          type="text/javascript"
          src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
        ></script>
        <style type="text/css" media="screen, projection">
          @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        </style>
      </div>
    </div>
    <div>
      <script>
        window.fwSettings = { widget_id: 48000000901 };
        !(function() {
          if ('function' != typeof window.FreshworksWidget) {
            var n = function() {
              n.q.push(arguments);
            };
            (n.q = []), (window.FreshworksWidget = n);
          }
        })();
      </script>
      <script type="text/javascript" src="https://widget.freshworks.com/widgets/48000000901.js" async defer></script>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConsumerFreshdeskWidget',
  data() {
    return {
      freshdeskFormHTML: ``,
    };
  },
};
</script>
